import { useEffect, useState } from 'react'
import { getGoogleAnalyticsUserIdFromBrowserCookie } from '@utils/get-user-id-from-cookie'

export const useUserToken = (): { userIdFromCookie?: string; csrfToken?: string } => {
  const [userIdFromCookie, setUserIdFromCookie] = useState<string>()
  useEffect(() => {
    const userIdFromCookie = getGoogleAnalyticsUserIdFromBrowserCookie('_ga')

    if (userIdFromCookie && window.dataLayer) {
      setUserIdFromCookie(userIdFromCookie)
      window.dataLayer.push({ algoliaUserToken: userIdFromCookie })
    }
  }, [])

  return { userIdFromCookie }
}
