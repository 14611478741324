import { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react'
import { SelectOption } from '@curran-catalog/curran-atomic-library'

import { MeasureUnitKey } from '@utils/measures'
import { ObjAddonState } from './furniture-product'
import { ContentfulObjFlooringWeaveColor, FlooringSectionsIDs } from 'types'

export interface FlooringWeaveState {
  quantity: number
  basePrice: number
  subTotalPrice: number
  selectedColor?: ContentfulObjFlooringWeaveColor
  selectedAddons?: ObjAddonState[]
  weaveName?: string
  weaveId: string
  tabActive?: string
  sizeWallCovering?: SelectOption
  currentColorIndex?: number
  size: {
    isMetric: boolean
    unitMeasure?: SelectOption
    isError?: boolean
    errorMessage?: string
  } & { [key in MeasureUnitKey]?: string }
  displayWeaveColor?: boolean
  algoliaQueryId?: string
}

export enum FlooringWeaveActionTypes {
  SET_TAB_ACTIVE = '@set-tab-active',
  CLEAR_NO_REQUIRED_OPTIONS = '@clear-no-required-options',
  CLEAR_ALL_OPTIONS = '@clear-all-options',

  SELECT_COLOR = '@select-color',

  SET_WEAVE_ID = '@set-weave-ID',
  SET_WEAVE_NAME = '@set-weave-name',
  SET_BASE_PRICE = '@set-base-price',
  SET_TOTAL_PRICE = '@set-total-price',
  SET_IS_METRIC = '@set-is-metric',
  SET_MEASURE_SIZE_FIELDS = '@set-measure-size-fields',
  RESET_MEASURE_SIZE_FIELDS = '@reset-measure-size-fields',
  SELECT_UNIT_MEASURE = '@select-unit-measure',

  // Wall-covering
  SET_WALL_COVERING_SIZE = '@set-wall-covering-size',
  SELECT_SIZE = '@select-size',

  SELECT_ADDONS = '@select-addons',
  SET_SIZE_ERROR = '@set-size-error',

  UPDATE_QUANTITY = '@update-quantity',
  INCREASE_QUANTITY = '@increase-quantity',
  DECREASE_QUANTITY = '@decrease-quantity',

  SET_CURRENT_COLOR_INDEX = '@selected-current-color-image',

  // Algolia
  SET_CURRENT_QUERY_ID = '@set-current-query-id',
}

export interface FlooringWeaveAction {
  type: FlooringWeaveActionTypes
  colorPayload?: {
    color: ContentfulObjFlooringWeaveColor
    displayWeaveColor?: boolean
  }
  fieldValuePayload?: string
  fieldKeyPayload?: MeasureUnitKey
  sizeOptionPayload?: SelectOption
  addonsPayload?: ObjAddonState[]
  quantityPayload?: number
  basePricePayload?: number
  weaveIdPayload?: string
  weaveNamePayload?: string
  tabActivePayload?: FlooringSectionsIDs
  currentColorIndexPayload?: number
  algoliaQueryIdPayload?: string
  errorSizePayload?: {
    isError: boolean
    errorMessage: string
  }
}

const INITIAL_STATE: FlooringWeaveState = {
  quantity: 1,
  weaveId: '',
  basePrice: 0,
  subTotalPrice: 0,
  size: {
    isMetric: false,
  },
  displayWeaveColor: false,
}

export type FlooringWeaveContextType = {
  state: FlooringWeaveState
  dispatch: Dispatch<FlooringWeaveAction>
}

export const FlooringWeaveContext = createContext<FlooringWeaveContextType>({
  state: INITIAL_STATE,
  dispatch: () => ({}),
})

const flooringWeaveReducer = (state: FlooringWeaveState, action: FlooringWeaveAction): FlooringWeaveState => {
  const totalAddonPrices =
    Array.isArray(state.selectedAddons) && state.selectedAddons.length > 0
      ? state.selectedAddons.reduce((acc, current) => {
          return acc + (current.price ?? 0)
        }, 0)
      : 0

  switch (action.type) {
    case FlooringWeaveActionTypes.UPDATE_QUANTITY: {
      return {
        ...state,
        quantity: Number(action.quantityPayload),
      }
    }

    case FlooringWeaveActionTypes.INCREASE_QUANTITY: {
      return {
        ...state,
        quantity: Number(state.quantity) + 1,
      }
    }

    case FlooringWeaveActionTypes.DECREASE_QUANTITY: {
      return {
        ...state,
        quantity: state.quantity - 1,
      }
    }

    case FlooringWeaveActionTypes.SET_BASE_PRICE: {
      return {
        ...state,
        basePrice: action.basePricePayload ?? 0,
        subTotalPrice: action.basePricePayload ?? 0 * state.quantity + totalAddonPrices,
      }
    }

    case FlooringWeaveActionTypes.SET_TOTAL_PRICE: {
      return {
        ...state,
        subTotalPrice: state.basePrice * state.quantity + totalAddonPrices,
      }
    }

    case FlooringWeaveActionTypes.SELECT_ADDONS: {
      return {
        ...state,
        selectedAddons: action.addonsPayload ?? undefined,
      }
    }

    case FlooringWeaveActionTypes.SELECT_COLOR: {
      return {
        ...state,
        selectedColor: action.colorPayload?.color ?? undefined,
        displayWeaveColor: action.colorPayload?.displayWeaveColor == false ? false : true,
      }
    }

    case FlooringWeaveActionTypes.SET_WEAVE_ID: {
      return {
        ...state,
        weaveId: action.weaveIdPayload ?? '',
      }
    }

    case FlooringWeaveActionTypes.SET_WEAVE_NAME: {
      return {
        ...state,
        weaveName: action.weaveNamePayload ?? '',
      }
    }

    case FlooringWeaveActionTypes.SET_IS_METRIC: {
      return {
        ...state,
        size: {
          ...state.size,
          isMetric: !state.size.isMetric,
        },
      }
    }

    case FlooringWeaveActionTypes.SELECT_UNIT_MEASURE: {
      return {
        ...state,
        size: {
          ...state.size,
          unitMeasure: action.sizeOptionPayload,
        },
      }
    }

    case FlooringWeaveActionTypes.SET_MEASURE_SIZE_FIELDS: {
      return {
        ...state,
        size: {
          ...state.size,
          [action.fieldKeyPayload ?? '']: action.fieldValuePayload ?? '',
        },
      }
    }

    case FlooringWeaveActionTypes.RESET_MEASURE_SIZE_FIELDS: {
      return {
        ...state,
        size: {
          ...state.size,
          sqyd: '',
          sqft: '',
          lft: '',
          lftIn: '',
          sqm: '',
          lm: '',
        },
      }
    }

    case FlooringWeaveActionTypes.SET_SIZE_ERROR: {
      return {
        ...state,
        size: {
          ...state.size,
          isError: action.errorSizePayload?.isError,
          errorMessage: action.errorSizePayload?.errorMessage,
        },
      }
    }

    case FlooringWeaveActionTypes.SET_WALL_COVERING_SIZE: {
      return {
        ...state,
        sizeWallCovering: action.sizeOptionPayload,
      }
    }

    case FlooringWeaveActionTypes.CLEAR_NO_REQUIRED_OPTIONS: {
      return {
        ...state,
        selectedAddons: undefined,
      }
    }
    case FlooringWeaveActionTypes.CLEAR_ALL_OPTIONS: {
      return {
        ...INITIAL_STATE,
        basePrice: action.basePricePayload ?? 0,
        subTotalPrice: action.basePricePayload ?? 0 * INITIAL_STATE.quantity,
      }
    }

    case FlooringWeaveActionTypes.SET_TAB_ACTIVE: {
      return {
        ...state,
        tabActive: action.tabActivePayload,
      }
    }

    case FlooringWeaveActionTypes.SET_CURRENT_COLOR_INDEX: {
      return {
        ...state,
        currentColorIndex: action.currentColorIndexPayload,
      }
    }

    case FlooringWeaveActionTypes.SET_CURRENT_QUERY_ID: {
      return {
        ...state,
        algoliaQueryId: action.algoliaQueryIdPayload,
      }
    }

    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

export const FlooringWeaveProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(flooringWeaveReducer, INITIAL_STATE)

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <FlooringWeaveContext.Provider value={value}>{children}</FlooringWeaveContext.Provider>
}
