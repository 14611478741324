import { environment } from '@config/environment'
export const pageview = () => {
  window.fbq && window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const trackEvent = (name: string, options = {}) => {
  if (environment.analytics.fPixelIdTest) {
    options = { ...options, ...{ test_event_code: `'${environment.analytics.fPixelIdTest}'` } }
  }
  window.fbq('track', name, options)
}
