// Google Analytics Cookie User Token ""
function extractGoogleAnalyticsUserIdFromCookie(gaCookie: string) {
  if (gaCookie) {
    // Remove the Google Analytics tracker from the device ID.
    const userIdParts = gaCookie.split('.').slice(-2)
    if (userIdParts.length === 2) {
      return userIdParts.join('_')
    }
  }
  return undefined
}

function getBrowserCookie(cookieName: string) {
  if (typeof document !== 'object' || typeof document.cookie !== 'string') {
    return undefined
  }
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}

/**
 * Returns the Google Analytics User ID from a browser cookie name.
 * @example `getGoogleAnalyticsUserIdFromBrowserCookie('_ga')`
 */
export function getGoogleAnalyticsUserIdFromBrowserCookie(cookieName: string) {
  const browserCookie = getBrowserCookie(cookieName)

  if (!browserCookie) {
    return undefined
  }

  return extractGoogleAnalyticsUserIdFromCookie(browserCookie)
}
