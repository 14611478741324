import { Document } from '@contentful/rich-text-types'

import {
  ContentfulObjVendor,
  Status,
  ContentfulObjFurnitureFabricGrade,
  ContentfulObjFurnitureCombo,
  CloudinaryAsset,
  ContentfulElement,
  ContentType,
} from 'types'

export enum SupportedProductTypes {
  RECTANGULAR = 'rectangular',
  CIRCULAR = 'circular',
  WALL_TO_WALL = 'wall-to-wall',
}

export type ContentfulObjAddon = {
  addonName: string
  addonNameSlug: string
  sku: string
  status: Status
  vendorId: string
  vendor?: ContentfulObjVendor
  description?: Document
  fabricGrades?: ContentfulObjFurnitureFabricGrade[]
  combos?: ContentfulObjFurnitureCombo[]
  images?: CloudinaryAsset[]
  supportedProductTypes?: SupportedProductTypes[]
} & ContentfulElement<ContentType.OBJ_ADDON>
