import { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react'

const INITIAL_SIDEBAR_STATE = { isOpen: false }

type SidebarState = {
  isOpen: boolean
}

/* Action */
export enum SidebarMenuActionTypes {
  TOGGLE_MODAL = '@toggle-modal',
}
export interface SidebarAction {
  type: SidebarMenuActionTypes
  payload: boolean
}

/** Reducer */
const sidebarReducer: (state: SidebarState, action: SidebarAction) => SidebarState = (
  state = INITIAL_SIDEBAR_STATE,
  action: SidebarAction,
): SidebarState => {
  switch (action.type) {
    case SidebarMenuActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        isOpen: action.payload,
      }
    }
    default:
      return state
  }
}

/** Context */
export type SidebarContextType = {
  state: SidebarState
  dispatch: Dispatch<SidebarAction>
}
export const SidebarContext = createContext<SidebarContextType>({
  state: INITIAL_SIDEBAR_STATE,
  dispatch: () => ({}),
})

/** Provider */
export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(sidebarReducer, INITIAL_SIDEBAR_STATE)
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])
  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
}
