import { useState } from 'react'

export const useSessionStorage = <T>(key: string, defaultValue: T) => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue
    }

    try {
      const value = window.sessionStorage.getItem(key)

      if (value) {
        return JSON.parse(value)
      } else {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })

  const setValue = (newValue: string) => {
    try {
      window.sessionStorage.setItem(key, JSON.stringify(newValue))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
    setStoredValue(newValue)
  }

  const removeValue = () => {
    try {
      window.sessionStorage.removeItem(key)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  return [storedValue, setValue, removeValue]
}
