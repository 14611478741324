import { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react'

import {
  ContentfulObjAddon,
  ContentfulObjAddonGroup,
  ContentfulObjFurnitureFabric,
  ContentfulObjFurnitureFabricGrade,
  ContentfulObjFurnitureFrameSlingCol,
  ErrorFurniturePrices,
  FurniturePrices,
} from 'types'

export type ObjFabricState = ContentfulObjFurnitureFabric & {
  price?: number
  orderSku?: string
  uniqueID?: string
  cushionGroupId?: string
  cushionGroupName?: string
  isPopover?: boolean
  hasQuantity?: boolean
  quantity?: number
}

export type ObjFrameSlingColState = ContentfulObjFurnitureFrameSlingCol & {
  parentPrimaryId?: string
  parentSecondaryId?: string
  parentTertiaryId?: string
  price?: number
  isPopover?: boolean
}

export type ObjAddonState = ContentfulObjAddon & {
  isChecked?: boolean
  price?: number
  quantity?: number
  orderSku?: string
  addonPrices?: FurniturePrices | ErrorFurniturePrices
  uniqueID?: string
}

export interface FurnitureProductState {
  quantity: number
  basePrice: number
  basePriceNotFound: boolean
  mainCushionPriceNotFound: boolean
  optionalCushionGroupPriceNotFound: boolean
  addonPriceNotFound: boolean
  addonsGroupPriceNotFound: boolean
  startingPrice: number
  cushionMinPrice: number
  totalPrice: number

  orderSku?: string
  isLoadingPrices: boolean

  productsDisabled?: boolean
  clearSelectionsDisabled?: boolean

  fabric?: ObjFabricState
  presetFabricGrade?: ContentfulObjFurnitureFabricGrade
  primaryComboFrameSlingCol?: ObjFrameSlingColState
  secondaryComboFrameSlingCol?: ObjFrameSlingColState
  tertiaryComboFrameSlingCol?: ObjFrameSlingColState
  quaternaryComboFrameSlingCol?: ObjFrameSlingColState
  mainCushionFabric?: ObjFabricState
  uniqueMainCushionFabrics?: ObjFabricState[]
  optionalCushionGroupFabrics?: ObjFabricState[]
  addonsFabricOrFrameSlingCol?: ObjAddonState[]
  addonsGroupFabricOrFrameSlingCol?: { [id: ContentfulObjAddonGroup['CONTENTFUL_ID']]: ObjAddonState[] }
  initialProductState?: FurnitureProductState
  algoliaQueryId?: string
}

export enum FurnitureProductActionTypes {
  SET_QUANTITY = '@set-quantity',

  SET_ORDER_SKU = '@set-order-sku',
  SET_BASE_PRICE_NOT_FOUND = '@set-has-base-price-found',
  SET_MAIN_CUSHION_PRICE_NOT_FOUND = '@set-main-cushion-price-not-found',
  SET_OPTIONAL_CUSHION_GROUP_PRICE_NOT_FOUND = '@set-optional-cushion-group-price-not-found',
  SET_ADDON_PRICE_NOT_FOUND = '@set-addon-price-not-found',
  SET_ADDONS_GROUP_PRICE_NOT_FOUND = '@set-addons-group_price-not-found',
  SET_BASE_PRICE = '@set-base-price',
  SET_STARTING_PRICE = '@set-starting-price',
  SET_BASE_CUSHION_MIN_PRICE = '@set-base-cushion-min-price',
  SET_TOTAL_PRICE = '@set-total-price',

  SET_LOADING_PRICES = '@set-loading-prices',
  SET_PRODUCTS_DISABLED = '@set-products-disabled',
  SET_CLEAR_SELECTIONS = '@set-clear-selections',
  SET_DISABLED_STATE = '@set-disabled-state',

  SET_FABRIC_GRADE = '@set-fabric-grade',
  SET_HAS_PRESET_FABRICS = '@set-has-preset-fabrics',
  SET_PRIMARY_COMBO = '@set-primary-combo',
  SET_SECONDARY_COMBO = '@set-secondary-combo',
  SET_TERTIARY_COMBO = '@set-tertiary-combo',
  SET_QUATERNARY_COMBO = '@set-quaternary-combo',
  SET_MAIN_CUSHION = '@set-main-cushion',
  SET_UNIQUE_MAIN_CUSHION_FABRICS = '@set-unique-main-cushion-fabrics',
  SET_OPTIONAL_CUSHIONS_GROUP = 'set-optional-cushions-group',
  SET_ADDONS = '@set-addons',
  SET_ADDONS_GROUP = '@set-addons-group',
  SET_INITIAL_STATE_PRODUCT = '@set-initial-state-product',

  CLEAR_NO_REQUIRED_OPTIONS = '@clear-no-required-options',
  RESET_ALL_OPTIONS = '@reset-all-options',

  // Algolia
  SET_CURRENT_QUERY_ID = '@set-current-query-id',
}

export type SetDisabledState = {
  type: FurnitureProductActionTypes.SET_DISABLED_STATE
  payload: Pick<FurnitureProductState, 'productsDisabled' | 'clearSelectionsDisabled'>
}

export type SetQuantityAction = {
  type: FurnitureProductActionTypes.SET_QUANTITY
  quantity: number
}

export type SetOrderSkuAction = {
  type: FurnitureProductActionTypes.SET_ORDER_SKU
  orderSku: string
}

export type SetBasePriceNotFoundAction = {
  type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND
  basePriceNotFound: boolean
}

export type SetMainCushionPriceNotFoundAction = {
  type: FurnitureProductActionTypes.SET_MAIN_CUSHION_PRICE_NOT_FOUND
  mainCushionPriceNotFound: boolean
}

export type SetOptionalCushionGroupPriceNotFoundAction = {
  type: FurnitureProductActionTypes.SET_OPTIONAL_CUSHION_GROUP_PRICE_NOT_FOUND
}

export type SetAddonPriceNotFoundAction = {
  type: FurnitureProductActionTypes.SET_ADDON_PRICE_NOT_FOUND
  addonPriceNotFound: boolean
}

export type SetAddonsGroupPriceNotFoundAction = {
  type: FurnitureProductActionTypes.SET_ADDONS_GROUP_PRICE_NOT_FOUND
}

export type SetBasePriceAction = {
  type: FurnitureProductActionTypes.SET_BASE_PRICE
  basePrice: number
}

export type SetStartingPriceAction = {
  type: FurnitureProductActionTypes.SET_STARTING_PRICE
  startingPrice: number
}

export type SetBaseCushionMinPriceAction = {
  type: FurnitureProductActionTypes.SET_BASE_CUSHION_MIN_PRICE
  cushionMinPrice: number
}

export type SetTotalPriceAction = {
  type: FurnitureProductActionTypes.SET_TOTAL_PRICE
}

export type SetLoadingPricesAction = {
  type: FurnitureProductActionTypes.SET_LOADING_PRICES
  isLoadingPrices: boolean
}

export type SetProductsDisabledAction = {
  type: FurnitureProductActionTypes.SET_PRODUCTS_DISABLED
  areProductsDisabled: boolean
}

export type SetClearSelectionsAction = {
  type: FurnitureProductActionTypes.SET_CLEAR_SELECTIONS
  isClearSelectionDisabled: boolean
}

export type SetFabricGradeAction = {
  type: FurnitureProductActionTypes.SET_FABRIC_GRADE
  fabricGrade: ObjFabricState
}

export type SetHasPresetFabricsAction = {
  type: FurnitureProductActionTypes.SET_HAS_PRESET_FABRICS
  presetFabricGrade: ContentfulObjFurnitureFabricGrade
}

export type SetPrimaryComboAction = {
  type: FurnitureProductActionTypes.SET_PRIMARY_COMBO
  primaryComboFrameSlingCol: ObjFrameSlingColState
}

export type SetSecondaryComboAction = {
  type: FurnitureProductActionTypes.SET_SECONDARY_COMBO
  secondaryComboFrameSlingCol?: ObjFrameSlingColState
}

export type SetTertiaryComboAction = {
  type: FurnitureProductActionTypes.SET_TERTIARY_COMBO
  tertiaryComboFrameSlingCol?: ObjFrameSlingColState
}

export type SetQuaternaryComboAction = {
  type: FurnitureProductActionTypes.SET_QUATERNARY_COMBO
  quaternaryComboFrameSlingCol?: ObjFrameSlingColState
}

export type SetMainCushionAction = {
  type: FurnitureProductActionTypes.SET_MAIN_CUSHION
  mainCushionFabric: ObjFabricState
}

export type SetUniqueMainCushionFabricsAction = {
  type: FurnitureProductActionTypes.SET_UNIQUE_MAIN_CUSHION_FABRICS
  uniqueMainCushionFabrics: ObjFabricState[]
}

export type SetOptionalCushionsGroupAction = {
  type: FurnitureProductActionTypes.SET_OPTIONAL_CUSHIONS_GROUP
  optionalCushionGroupFabrics: ObjFabricState[]
}

export type SetAddonsAction = {
  type: FurnitureProductActionTypes.SET_ADDONS
  addonsFabricOrFrameSlingCol: ObjAddonState[]
}

export type SetAddonsGroupAction = {
  type: FurnitureProductActionTypes.SET_ADDONS_GROUP
  addonGroupId: ContentfulObjAddonGroup['CONTENTFUL_ID']
  addons: ObjAddonState[]
}

export type SetInitialStateProductAction = {
  type: FurnitureProductActionTypes.SET_INITIAL_STATE_PRODUCT
  additionalState?: Partial<FurnitureProductState>
}

export type ClearNoRequiredOptionsAction = {
  type: FurnitureProductActionTypes.CLEAR_NO_REQUIRED_OPTIONS
}

export type ResetAllOptionsAction = {
  type: FurnitureProductActionTypes.RESET_ALL_OPTIONS
}

export type SetAlgoliaQueryIdAction = {
  type: FurnitureProductActionTypes.SET_CURRENT_QUERY_ID
  payload: string
}

export type FurnitureProductAction =
  | SetQuantityAction
  | SetOrderSkuAction
  | SetBasePriceNotFoundAction
  | SetMainCushionPriceNotFoundAction
  | SetOptionalCushionGroupPriceNotFoundAction
  | SetAddonPriceNotFoundAction
  | SetAddonsGroupPriceNotFoundAction
  | SetBasePriceAction
  | SetStartingPriceAction
  | SetBaseCushionMinPriceAction
  | SetTotalPriceAction
  | SetLoadingPricesAction
  | SetProductsDisabledAction
  | SetClearSelectionsAction
  | SetFabricGradeAction
  | SetHasPresetFabricsAction
  | SetPrimaryComboAction
  | SetSecondaryComboAction
  | SetTertiaryComboAction
  | SetQuaternaryComboAction
  | SetMainCushionAction
  | SetUniqueMainCushionFabricsAction
  | SetOptionalCushionsGroupAction
  | SetAddonsAction
  | SetAddonsGroupAction
  | SetInitialStateProductAction
  | SetDisabledState
  | ClearNoRequiredOptionsAction
  | ResetAllOptionsAction
  | SetAlgoliaQueryIdAction

const INITIAL_STATE: FurnitureProductState = {
  quantity: 1,
  startingPrice: 0,
  basePriceNotFound: false,
  mainCushionPriceNotFound: false,
  optionalCushionGroupPriceNotFound: false,
  addonPriceNotFound: false,
  addonsGroupPriceNotFound: false,
  basePrice: 0,
  cushionMinPrice: 0,
  totalPrice: 0,
  isLoadingPrices: true,
  productsDisabled: false,
  clearSelectionsDisabled: true,
}

export type FurnitureProductContextType = {
  state: FurnitureProductState
  dispatch: Dispatch<FurnitureProductAction>
}

export const FurnitureProductContext = createContext<FurnitureProductContextType>({
  state: INITIAL_STATE,
  dispatch: () => ({}),
})

const furnitureProductReducer = (
  state: FurnitureProductState,
  action: FurnitureProductAction,
): FurnitureProductState => {
  switch (action.type) {
    case FurnitureProductActionTypes.SET_QUANTITY: {
      return {
        ...state,
        quantity: action.quantity,
      }
    }

    case FurnitureProductActionTypes.SET_BASE_CUSHION_MIN_PRICE: {
      return {
        ...state,
        cushionMinPrice: action.cushionMinPrice,
      }
    }

    case FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND: {
      return {
        ...state,
        basePriceNotFound: action.basePriceNotFound,
      }
    }

    case FurnitureProductActionTypes.SET_MAIN_CUSHION_PRICE_NOT_FOUND: {
      return {
        ...state,
        mainCushionPriceNotFound: action.mainCushionPriceNotFound,
      }
    }

    case FurnitureProductActionTypes.SET_OPTIONAL_CUSHION_GROUP_PRICE_NOT_FOUND: {
      return {
        ...state,
        optionalCushionGroupPriceNotFound: Boolean(
          state.optionalCushionGroupFabrics?.some(({ orderSku, price }) => !orderSku && !price),
        ),
      }
    }

    case FurnitureProductActionTypes.SET_ADDON_PRICE_NOT_FOUND: {
      return {
        ...state,
        addonPriceNotFound: action.addonPriceNotFound,
      }
    }

    case FurnitureProductActionTypes.SET_ADDONS_GROUP_PRICE_NOT_FOUND: {
      return {
        ...state,
        addonsGroupPriceNotFound: state.addonsGroupFabricOrFrameSlingCol
          ? Object.values(state.addonsGroupFabricOrFrameSlingCol)
              .reduce((acc, val) => acc.concat(val), [])
              .some(({ orderSku, price }) => !orderSku && !price)
          : false,
      }
    }

    case FurnitureProductActionTypes.SET_BASE_PRICE: {
      return {
        ...state,
        basePrice: action.basePrice,
      }
    }

    case FurnitureProductActionTypes.SET_STARTING_PRICE: {
      return {
        ...state,
        startingPrice: action.startingPrice,
      }
    }

    case FurnitureProductActionTypes.SET_TOTAL_PRICE: {
      const totalMainCushionPrices = (state.mainCushionFabric?.price ?? 0) * state.quantity
      const totalOptionalCushionsGroupPrices =
        Array.isArray(state.optionalCushionGroupFabrics) && state.optionalCushionGroupFabrics.length > 0
          ? state.optionalCushionGroupFabrics.reduce(
              (acc, current) =>
                acc +
                (current.price ?? 0) * (current.hasQuantity && current.quantity ? current.quantity : state.quantity),
              0,
            )
          : 0

      const totalAddonsPrices =
        Array.isArray(state.addonsFabricOrFrameSlingCol) && state.addonsFabricOrFrameSlingCol.length > 0
          ? state.addonsFabricOrFrameSlingCol.reduce((acc, current) => acc + (current.price ?? 0), 0)
          : 0

      const totalAddonsGroupPrices = state.addonsGroupFabricOrFrameSlingCol
        ? Object.values(state.addonsGroupFabricOrFrameSlingCol)
            .reduce((acc, val) => acc.concat(val), [])
            .reduce((total, { price }) => total + (price ?? 0), 0)
        : 0

      return {
        ...state,
        totalPrice:
          state.basePrice * state.quantity +
          totalMainCushionPrices +
          totalOptionalCushionsGroupPrices +
          totalAddonsPrices +
          totalAddonsGroupPrices,
      }
    }

    case FurnitureProductActionTypes.SET_FABRIC_GRADE: {
      return {
        ...state,
        fabric: action.fabricGrade,
      }
    }

    case FurnitureProductActionTypes.SET_HAS_PRESET_FABRICS: {
      return {
        ...state,
        presetFabricGrade: action.presetFabricGrade,
      }
    }

    case FurnitureProductActionTypes.SET_PRIMARY_COMBO: {
      return {
        ...state,
        primaryComboFrameSlingCol: action.primaryComboFrameSlingCol,
      }
    }

    case FurnitureProductActionTypes.SET_SECONDARY_COMBO: {
      return {
        ...state,
        secondaryComboFrameSlingCol: action.secondaryComboFrameSlingCol,
      }
    }

    case FurnitureProductActionTypes.SET_TERTIARY_COMBO: {
      return {
        ...state,
        tertiaryComboFrameSlingCol: action.tertiaryComboFrameSlingCol,
      }
    }

    case FurnitureProductActionTypes.SET_QUATERNARY_COMBO: {
      return {
        ...state,
        quaternaryComboFrameSlingCol: action.quaternaryComboFrameSlingCol,
      }
    }

    case FurnitureProductActionTypes.SET_MAIN_CUSHION: {
      return {
        ...state,
        mainCushionFabric: action.mainCushionFabric,
      }
    }

    case FurnitureProductActionTypes.SET_UNIQUE_MAIN_CUSHION_FABRICS: {
      return {
        ...state,
        uniqueMainCushionFabrics: action.uniqueMainCushionFabrics,
      }
    }

    case FurnitureProductActionTypes.SET_OPTIONAL_CUSHIONS_GROUP: {
      return {
        ...state,
        optionalCushionGroupFabrics: action.optionalCushionGroupFabrics,
      }
    }

    case FurnitureProductActionTypes.SET_ADDONS: {
      return {
        ...state,
        addonsFabricOrFrameSlingCol: action.addonsFabricOrFrameSlingCol,
      }
    }

    case FurnitureProductActionTypes.SET_ADDONS_GROUP: {
      return {
        ...state,
        addonsGroupFabricOrFrameSlingCol: {
          ...state.addonsGroupFabricOrFrameSlingCol,
          [action.addonGroupId ?? '']: action.addons ?? [],
        },
      }
    }

    case FurnitureProductActionTypes.SET_ORDER_SKU: {
      return {
        ...state,
        orderSku: action.orderSku,
      }
    }

    case FurnitureProductActionTypes.SET_INITIAL_STATE_PRODUCT: {
      return {
        ...state,
        initialProductState: { ...state, initialProductState: undefined },
        optionalCushionGroupFabrics: undefined,
        addonsFabricOrFrameSlingCol: undefined,
        addonsGroupFabricOrFrameSlingCol: undefined,
        ...{ ...(action?.additionalState ? action.additionalState : {}) },
      }
    }

    case FurnitureProductActionTypes.CLEAR_NO_REQUIRED_OPTIONS: {
      return {
        ...state,
        optionalCushionGroupFabrics: undefined,
        addonsFabricOrFrameSlingCol: undefined,
        addonsGroupFabricOrFrameSlingCol: undefined,
      }
    }

    case FurnitureProductActionTypes.RESET_ALL_OPTIONS: {
      return {
        ...{ ...(state.initialProductState ? state.initialProductState : state) },
        isLoadingPrices: false,
        productsDisabled: false,
        clearSelectionsDisabled: true,
        initialProductState: state.initialProductState,
        optionalCushionGroupFabrics: undefined,
        addonsFabricOrFrameSlingCol: undefined,
        addonsGroupFabricOrFrameSlingCol: undefined,
      }
    }

    case FurnitureProductActionTypes.SET_LOADING_PRICES: {
      return {
        ...state,
        isLoadingPrices: action.isLoadingPrices,
      }
    }
    case FurnitureProductActionTypes.SET_PRODUCTS_DISABLED: {
      return {
        ...state,
        productsDisabled: action.areProductsDisabled,
      }
    }
    case FurnitureProductActionTypes.SET_CLEAR_SELECTIONS: {
      return {
        ...state,
        clearSelectionsDisabled: action.isClearSelectionDisabled,
      }
    }
    case FurnitureProductActionTypes.SET_DISABLED_STATE: {
      return {
        ...state,
        ...{ ...(action.payload ? action.payload : {}) },
      }
    }

    case FurnitureProductActionTypes.SET_CURRENT_QUERY_ID: {
      return {
        ...state,
        algoliaQueryId: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export const FurnitureProductProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(furnitureProductReducer, INITIAL_STATE)

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <FurnitureProductContext.Provider value={value}>{children}</FurnitureProductContext.Provider>
}
