import Script from 'next/script'

import { environment } from '@config/environment'
import { FURNITURE_ORGANIZATION_LINKS, SISALCARPET_ORGANIZATION_LINKS } from 'constants/organization'

const organizationDataForGoogleMerchantCenter = JSON.stringify({
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'Organization',
      name: environment.siteDomain === 'sisalcarpet' ? 'sisalcarpet.com' : 'CurranOUTDOOR',
      url: environment.siteDomain === 'sisalcarpet' ? environment.sisalcarpetRootUrl : environment.furnitureRootUrl,
      parentOrganization: {
        name: environment.siteDomain === 'sisalcarpet' ? 'Curran' : '',
      },
      sameAs: environment.siteDomain === 'sisalcarpet' ? SISALCARPET_ORGANIZATION_LINKS : FURNITURE_ORGANIZATION_LINKS,
      description:
        environment.siteDomain === 'sisalcarpet'
          ? 'sisalcarpet.com is the premiere source for custom area rugs and wall-to-wall carpets crafted from timeless natural fibers like sisal, jute & wool.'
          : 'Curran is the premier e-commerce resource for luxury European outdoor furniture and umbrellas',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '877-757-4725',
        url:
          environment.siteDomain === 'sisalcarpet'
            ? 'https://www.sisalcarpet.com/customer-service'
            : 'https://www.curranonline.com/customer-service',
        contactType: 'Customer Service',
        email: 'service@curranonline.com',
      },
      logo: {
        '@type': 'ImageObject',
        url: 'https://res.cloudinary.com/curran-catalog/image/upload/v1672785150/Marketing/Logos%20and%20Branding/Co-branded%20Logos/CurranSisalcarpet_Logo_black_rgb_hywf32.png',
      },
      address: {
        '@type': 'PostalAddress',
        streetAddress: '1932 1st Ave, Suite 800',
        addressLocality: 'Seattle',
        addressRegion: 'WA',
        postalCode: '98101',
        addressCountry: 'US',
      },
    },
  ],
})

export const Scripts = () =>
  environment.isProd ? (
    <>
      {/* ref: https://support.cookiebot.com/hc/en-us/articles/360009192739-Google-Tag-Manager-and-Automatic-cookie-blocking */}
      {/* Google Consent Mode */}
      <Script id="google-consent-mode" data-cookieconsent="ignore" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments)
          }
          gtag("consent", "default", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 500
          });
          gtag("set", "ads_data_redaction", true);
        `}
      </Script>

      {/* End Google Consent Mode */}
      {/* Google Tag Manager */}
      <Script id="google-tag-manager" data-cookieconsent="ignore" strategy="lazyOnload">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${environment.analytics.gtmId}');
        `}
      </Script>
      {/* End Google Tag Manager */}
      {/* Cookiebot CMP */}
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={environment.analytics.cookiebotId}
        data-blockingmode="auto"
        type="text/javascript"
        strategy="lazyOnload"
      />
      {/* End Cookiebot CMP */}
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="lazyOnload"
        data-cookieconsent="ignore"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${environment.analytics.fPixelId});
            fbq('track', 'PageView');
          `,
        }}
      />
      {/* End Global Site Code Pixel - Facebook Pixel */}
      {/* Start of HubSpot Embed Code */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/21019888.js"
        strategy="lazyOnload"
      />
      {/* End of HubSpot Embed Code */}
      {/* Structured data Organization  */}
      {(environment.siteDomain === 'sisalcarpet' || environment.siteDomain === 'furniture') && (
        <Script
          id="structured-data-organization"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: organizationDataForGoogleMerchantCenter }}
        />
      )}
      {/* End of Structured data Organization  */}
    </>
  ) : null
