export const SOCIAL_MEDIA_LINKS = {
  FB_SISALCARPET: 'https://www.facebook.com/sisalcarpet.official/',
  INSTAGRAM_SISALCARPET: 'https://www.instagram.com/sisalcarpet/',
  PINTEREST_SISALCARPET: 'https://www.pinterest.com/sisalcarpet/',
  YT_SISALCARPET: 'https://www.youtube.com/@curran.sisalcarpet',
  LINKEDIN_SISALCARPET: 'https://www.linkedin.com/company/curran',
  FB_FURNITURE: 'https://www.facebook.com/curranofficial/',
  INSTAGRAM_FURNITURE: 'https://www.instagram.com/curran_official/',
  PINTEREST_FURNITURE: 'https://www.pinterest.com/curranonline/',
  YT_FURNITURE: 'https://www.youtube.com/@curran.sisalcarpet',
  LINKEDIN_FURNITURE: 'https://www.linkedin.com/company/curran',
}

export const SISALCARPET_ORGANIZATION_LINKS = [
  SOCIAL_MEDIA_LINKS.FB_SISALCARPET,
  SOCIAL_MEDIA_LINKS.INSTAGRAM_SISALCARPET,
  SOCIAL_MEDIA_LINKS.PINTEREST_SISALCARPET,
  SOCIAL_MEDIA_LINKS.YT_SISALCARPET,
  SOCIAL_MEDIA_LINKS.LINKEDIN_SISALCARPET,
]

export const FURNITURE_ORGANIZATION_LINKS = [
  SOCIAL_MEDIA_LINKS.FB_FURNITURE,
  SOCIAL_MEDIA_LINKS.INSTAGRAM_FURNITURE,
  SOCIAL_MEDIA_LINKS.PINTEREST_FURNITURE,
  SOCIAL_MEDIA_LINKS.YT_FURNITURE,
  SOCIAL_MEDIA_LINKS.LINKEDIN_FURNITURE,
]
