import type { AppProps } from 'next/app'
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react'
import '@curran-catalog/curran-atomic-library/dist/style.css'
import { Providers } from 'context'
import { Scripts } from '@utils/scripts'
import '../styles/globals.css'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { pageview } from '@utils/analytics/gtm'
import * as fbq from '@utils/analytics/fpixel'
import { useSessionStorage } from '@hooks/use-session-storage'
import NextNProgress from 'nextjs-progressbar'
import aa from 'search-insights'
import { environment } from '@config/environment'
import { useUserToken } from '@hooks/use-user-token'
import { getCurrentSynsisalRoute } from '@utils/get-synsisal-route'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [_route, setRoute] = useSessionStorage('x-previousTitleRoute', null)
  const [_categorySlug, setFlooringCategorySlug] = useSessionStorage('x-previewsFlooringCategorySlug', null)

  const { userIdFromCookie } = useUserToken()

  useEffect(() => {
    aa('init', {
      appId: `${environment.algolia.appId}`,
      apiKey: `${environment.algolia.publicApiKey}`,
      userToken: userIdFromCookie,
    })
  }, [userIdFromCookie])

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = () => {
      fbq.pageview()
    }

    const handleRouteChangeStart = () => {
      setRoute(getCurrentSynsisalRoute())
      setFlooringCategorySlug(window.location.pathname.split('/')[2] ?? null)
    }
    router.events.on('routeChangeStart', handleRouteChangeStart)

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
      router.events.off('routeChangeComplete', handleRouteChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events])

  return (
    <>
      <Scripts />
      <Providers>
        <NextNProgress
          color="#DEE2E6"
          showOnShallow={true}
          options={{
            showSpinner: false,
          }}
        />
        <ContentfulLivePreviewProvider locale="en-US">
          <Component {...pageProps} />
        </ContentfulLivePreviewProvider>
      </Providers>
    </>
  )
}

export default MyApp
