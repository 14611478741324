export enum ContentType {
  AT_BUTTON = 'atButton',
  AT_INFO_ITEM = 'atInfoItem',
  AT_LINK = 'atLink',
  AT_CLOUDINARY_ASSET = 'atCloudinaryAsset',
  AT_STEP = 'atStep',
  AT_IFRAME = 'atIframe',

  ML_ANNOUNCEMENT = 'mlAnnouncement',
  ML_BANNER = 'mlBanner',
  ML_BEAUTY_BANNER = 'mlBeautyBanner',
  ML_CARD = 'mlCard',
  ML_CONTACT = 'mlContact',
  ML_CONTENT_STRIP = 'mlContentStrip',
  ML_FLYOUT_MENU = 'mlFlyoutMenu',
  Ml_FORM_FIELD = 'mlFormField',
  ML_HEADING = 'mlHeading',
  ML_INFO = 'mlInfo',
  ML_MENU_ITEM = 'mlMenuItem',
  ML_RICH_TEXT = 'mlRichText',
  ML_VERTICAL = 'mlVertical',
  ML_VIDEO = 'mlVideo',
  ML_BANNER_OVERLAY = 'mlBannerOverlay',

  OR_COLLAPSE = 'orCollapse',
  OR_COLLAPSE_CONTAINER = 'orCollapseContainer',
  OR_CONTAINER = 'orContainer',
  OR_FOOTER = 'orFooter',
  OR_HEADER = 'orHeader',
  OR_NEWSLETTER = 'orNewsletter',
  OR_SEARCH = 'orSearch',
  OR_SIDEBAR_MENU = 'orSidebarMenu',
  OR_TABS = 'orTabs',
  OR_TAB_ITEM = 'orTabItem',
  OR_FORM = 'orForm',
  OR_STEPPER = 'orStepper',

  TM_CATALOG = 'tmCatalog',
  TM_PAGE_RESULTS = 'tmPageResults',
  TM_SEARCH = 'tmSearch',
  TM_SIDEBAR = 'tmSidebar',

  PG_PAGE = 'pgPage',

  OBJ_VENDOR = 'objVendor',
  OBJ_SHIPPING = 'objShipping',
  OBJ_DELIVERY = 'objDelivery',
  OBJ_SEO = 'objSeo',
  OBJ_ADDON = 'objAddon',
  OBJ_ANALYTICS = 'objAnalytics',

  OBJ_ADDON_GROUP = 'objAddonGroup',
  OBJ_FURNITURE_ASSOCIATED_PRODUCT = 'objFurnitureAssociatedProduct',
  OBJ_FURNITURE_COLLECTION = 'objFurnitureCollection',
  OBJ_FURNITURE_COMBO = 'objFurnitureCombo',
  OBJ_FURNITURE_FABRIC = 'objFurnitureFabric',
  OBJ_FURNITURE_FABRIC_GRADE = 'objFurnitureFabricGrade',
  OBJ_FURNITURE_FRAMESLINGCOL = 'objFurnitureFrameslingcol',
  OBJ_FURNITURE_MAIN_CUSHION = 'objFurnitureMainCushion',
  OBJ_FURNITURE_OPTIONAL_CUSHION = 'objFurnitureOptionalCushion',
  OBJ_FURNITURE_PRODUCT = 'objFurnitureProduct',
  OBJ_FURNITURE_SPECIFICATIONS = 'objFurnitureSpecifications',
  OBJ_FURNITURE_OPTIONAL_CUSHION_GROUP = 'objFurnitureOptionalCushionGroup',

  OBJ_FLOORING_WEAVE = 'objFlooringWeave',
  OBJ_FLOORING_BORDER = 'objFlooringBorder',
  OBJ_FLOORING_BORDER_COLOR = 'objFlooringBorderColor',
  OBJ_FLOORING_BORDER_COLOR_GROUP = 'objFlooringBorderColorGroup',
  OBJ_FLOORING_BORDER_GROUP = 'objFlooringBorderGroup',
  OBJ_FLOORING_RUGPAD = 'objFlooringRugpad',
  OBJ_FLOORING_RUGPAD_GROUP = 'objFlooringRugpadGroup',
  OBJ_FLOORING_WEAVE_COLOR = 'objFlooringWeaveColor',
  OBJ_FLOORING_WEAVE_COLOR_GROUP = 'objFlooringWeaveColorGroup',
  OBJ_FLOORING_SPECIFICATIONS = 'objFlooringSpecifications',
  OBJ_FLOORING_CARE = 'objFlooringCare',
  OBJ_FLOORING_INSTALLATION = 'objFlooringInstallation',
}
