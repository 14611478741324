import { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react'

export type Sample = {
  id: string
  name: string
  cartId: number
  sku: string
}

export interface FlooringSampleState {
  selectedRugSamples?: Sample[]
  selectedBorderSamples?: Sample[]
}

export enum FlooringSampleActionTypes {
  UPDATE_RUG_SAMPLES = '@update-rug-samples',
  UPDATE_BORDER_SAMPLES = '@update-border-samples',
}

export interface FlooringSampleAction {
  type: FlooringSampleActionTypes
  selectedRugPayload?: Sample[]
  selectedBorderPayload?: Sample[]
}

const INITIAL_STATE: FlooringSampleState = {
  selectedBorderSamples: [],
  selectedRugSamples: [],
}

export type FlooringSampleContextType = {
  state: FlooringSampleState
  dispatch: Dispatch<FlooringSampleAction>
}

export const FlooringSampleContext = createContext<FlooringSampleContextType>({
  state: INITIAL_STATE,
  dispatch: () => ({}),
})

const flooringSampleReducer = (state: FlooringSampleState, action: FlooringSampleAction): FlooringSampleState => {
  switch (action.type) {
    case FlooringSampleActionTypes.UPDATE_RUG_SAMPLES: {
      return {
        ...state,
        selectedRugSamples: action.selectedRugPayload,
      }
    }

    case FlooringSampleActionTypes.UPDATE_BORDER_SAMPLES: {
      return {
        ...state,
        selectedBorderSamples: action.selectedBorderPayload,
      }
    }

    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}

export const FlooringSampleProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(flooringSampleReducer, INITIAL_STATE)

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return <FlooringSampleContext.Provider value={value}>{children}</FlooringSampleContext.Provider>
}
