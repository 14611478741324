import { environment } from '@config/environment'
import { CloudinaryAsset } from './cloudinary'
import { Hit } from 'instantsearch.js'

export type Panels = {
  [key: string]: boolean
}

export enum Status {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const FABRIC_GRADES_DEFAULT_TITLE = 'Fabric Grades'
export const MAIN_CUSHIONS_DEFAULT_TITLE = 'Main Cushions'
export const ADDONS_DEFAULT_TITLE = 'Addons'

export enum ComboVariant {
  THUMBNAIL = 'thumbnail',
  RADIO = 'radio',
}

export enum AddonsGroupVariant {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum SiteDomain {
  FURNITURE = 'furniture',
  FLOORING = 'flooring',
  SISALCARPET = 'sisalcarpet',
  SYNSISAL = 'synsisal',
}

export interface Cart {
  id: number
  items: CartItem[]
  count: number
  subtotal: string
  freight: string
  discount: string
  tax: string
  total: string
  created_at: Date
  updated_at: Date
  activePromoCode?: string
  estimatedTax?: string
  estimatedTaxZip?: string
  isUpdatingTotals?: boolean
  hasBeenSent: boolean
}

export type CartItem = {
  description: string
  details: string
  discount: number
  excludedFromDiscount: boolean
  id: number
  imageAlt: string
  imageSrc: string
  isHidden: boolean
  isQuantityEditable: boolean
  isRemovable: boolean
  netPrice: string
  price: string
  quantity: number
  showDiscount: boolean
  sku: string
  metadata?: {
    contentful_id?: string
    type?: 'sample'
    subType?: 'weave' | 'border'
    link?: string
    notes?: string
    minimumQuantity?: number
    category?: string
    category2?: string
    category3?: string
    category4?: string
    category5?: string
    brand?: string
    itemListName?: string
    index?: number
    query_id?: string
  }
}

export type Address = {
  id?: number | null
  type: string
  name: string
  phone: string
  company: string | undefined
  address1: string
  address2: string | undefined
  city: string
  state: string | null
  zip: string
  country: string
}

export type Order = {
  id?: number | null
  reference: string
  status: string
  email: string
  billing: Address
  shipping: Address
  items: CartItem[]
  subtotal: string
  freight: string
  discount: string
  tax: string
  total: string
  pm_type: string
  pm_last_four: string
}

export type DeliveryMethod = {
  id: number
  title: string
  bullets: string[]
  price: string
  isEnhancedDelivery: boolean
}

export const SiteConfig: Record<
  SiteDomain,
  {
    title: string
    longTitle: string
    favicon: string
    tabHomeURL?: string
    tabFloorURL: string
    siteURL: string
    siteName?: string
  }
> = {
  flooring: {
    title: 'CurranFLOOR',
    longTitle: 'Beautiful Natural Fiber Rugs & Carpet | CurranFLOOR',
    favicon: '/images/furniture/favicon.ico',
    tabHomeURL: environment.furnitureRootUrl as string,
    tabFloorURL: '/',
    siteURL: environment.flooringRootUrl as string,
    siteName: 'flooring store',
  },
  furniture: {
    title: 'CurranOUTDOOR',
    longTitle: 'Luxury Outdoor Furniture | CurranOUTDOOR',
    favicon: '/images/furniture/favicon.ico',
    tabHomeURL: '/',
    tabFloorURL: environment.flooringRootUrl as string,
    siteURL: environment.furnitureRootUrl as string,
    siteName: 'curranonline store',
  },
  sisalcarpet: {
    title: 'sisalcarpet.com',
    longTitle: 'Luxury Sisal Rugs, Carpet & More | Sisalcarpet.com',
    favicon: '/images/synsisal/favicon.ico',
    tabHomeURL: environment.furnitureRootUrl as string,
    tabFloorURL: '/',
    siteURL: environment.sisalcarpetRootUrl as string,
    siteName: 'sisalcarpet store',
  },
  synsisal: {
    title: 'SynSisal®',
    longTitle: 'Synthetic Sisal Rugs & Carpet | SynSisal®',
    favicon: '/images/synsisal/favicon.ico',
    tabHomeURL: environment.synsisalRootUrl as string,
    tabFloorURL: '/',
    siteURL: environment.synsisalRootUrl as string,
    siteName: 'Synsisal',
  },
}

export enum CustomizeType {
  RECTANGULAR = 'Rectangular',
  CIRCULAR = 'Circular',
  WALL_TO_WALL = 'Wall-to-Wall',
}

export enum FlooringOrderButtonLabels {
  DEFAULT = 'Order',
  RUG_BUILDER = 'Price & Order',
  CALL_FOR_QUOTE = 'Request Quote',
}

export enum CornerStyleType {
  LAPPED = 'Lapped',
  MITERED = 'Mitered',
  MITERED_INCLUDED = 'Mitered corners are included.',
}

export enum OrderRugStepperIndexes {
  CUSTOMIZE = 0,
  BORDER = 1,
  ADDONS = 2,
  NOTES = 3,
  PREVIEW_CONFIRM = 4,
}

// Type hardcoded to use in Rug Builder -> Order Rug -> Border Step
export type ObjFlooringCornerStyle = {
  id: string
  name: CornerStyleType
  price: number
  description: string
  images: CloudinaryAsset[]
}

// Type hardcoded to use in Rug Builder -> Order Rug -> Addons Step
export enum ProductionRushState {
  YES = 'yes',
  NO = 'no',
}

export type ObjFlooringProductionRush = {
  id: string
  state: ProductionRushState
  price: number
  rushName: string
}

export enum FlooringSectionsIDs {
  PRICE_ORDER = '#price-order',
  SAMPLES = '#samples',
}

export enum TradeStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
}
export interface User {
  first_name: string
  last_name: string
  email: string
  phone: string
  created_at: string
  trade_status: TradeStatus
  trade_level: string | null
  addresses: Address[]
}

export type AnalyticsData = Omit<Hit, '__position'>
