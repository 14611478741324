export enum ResourceType {
  IMAGE = 'image',
  VIDEO = 'video',
  RAW = 'raw',
}

export enum DeliveryType {
  UPLOAD = 'upload',
  PRIVATE = 'private',
  AUTHENTICATED = 'authenticated',
}

export type CloudinaryContext = {
  custom: {
    [key: string]: string
  }
}

export enum CloudinaryFormat {
  WEBP = 'webp',
  JPG = 'jpg',
  GIF = 'gif',
  PNG = 'png',
  TIFF = 'tiff',
  SVG = 'svg',
  AI = 'ai',
  EPS = 'eps',
  PDF = 'pdf',
  MP4 = 'mp4',
}

export type CloudinaryAsset = {
  context: CloudinaryContext
  format: CloudinaryFormat
  height: number
  metadata: string[]
  original_secure_url: string
  original_url: string
  public_id: string
  raw_transformation: string
  resource_type: ResourceType
  secure_url: string
  tags: string[]
  type: DeliveryType
  url: string
  version: number
  width: number
  // external nextjs property
  priority?: boolean
}
