export const environment = {
  algolia: {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '',
    publicApiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ?? '',
    writeApiKey: process.env.ALGOLIA_WRITE_API_KEY ?? '',
    recordsIndex: process.env.NEXT_PUBLIC_ALGOLIA_RECORDS_INDEX ?? '',
    pageLinksIndex: process.env.NEXT_PUBLIC_ALGOLIA_PAGE_LINKS_INDEX ?? '',
  },
  contentful: {
    host: process.env.CONTENTFUL_HOST,
    previewHost: process.env.CONTENTFUL_PREVIEW_HOST,
    environment: process.env.CONTENTFUL_ENVIRONMENT ?? 'dev',

    //Sites
    spaceId: process.env.CONTENTFUL_SPACE_ID ?? '',
    deliveryToken: process.env.CONTENTFUL_DELIVERY_TOKEN ?? '',
    previewToken: process.env.CONTENTFUL_PREVIEW_TOKEN ?? '',

    //Products
    productsSpaceId: process.env.CONTENTFUL_PRODUCTS_SPACE_ID ?? '',
    productsDeliveryToken: process.env.CONTENTFUL_PRODUCTS_DELIVERY_TOKEN ?? '',
    productsPreviewToken: process.env.CONTENTFUL_PRODUCTS_PREVIEW_TOKEN ?? '',
  },
  recaptcha: {
    siteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? undefined,
    secretKey: process.env.RECAPTCHA_SECRET_KEY ?? undefined,
  },
  mailgun: {
    apiKey: process.env.MAILGUN_API_KEY ?? '',
    domain: process.env.MAILGUN_DOMAIN ?? '',
    fromEmail: process.env.MAILGUN_FROM_EMAIL ?? '',
    toEmails: process.env.MAILGUN_TO_EMAILS ?? '',
  },
  newsletter: {
    url: process.env.NEWSLETTER_URL_SERVICE,
    uParam: process.env.NEWSLETTER_U_PARAM,
    idParam: process.env.NEWSLETTER_ID_PARAM,
    inputName: process.env.NEWSLETTER_INPUT_NAME,

    portalId: process.env.NEXT_PUBLIC_NEWSLETTER_PORTAL_ID,
    formId: process.env.NEXT_PUBLIC_NEWSLETTER_FORM_ID,
  },
  analytics: {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    cookiebotId: process.env.NEXT_PUBLIC_COOKIEBOT_ID,
    fPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    fPixelIdTest: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID_TEST ?? null,
  },
  isProd: process.env.NODE_ENV === 'production',
  previewSecret: process.env.PREVIEW_SECRET,
  revalidateSecret: process.env.REVALIDATE_SECRET,
  updateStatusSecret: process.env.UPDATE_STATUS_SECRET,
  siteDomain: process.env.NEXT_PUBLIC_SITE_DOMAIN || 'furniture',
  flooringRootUrl: process.env.NEXT_PUBLIC_FLOORING_ROOT_URL,
  furnitureRootUrl: process.env.NEXT_PUBLIC_FURNITURE_ROOT_URL,
  sisalcarpetRootUrl: process.env.NEXT_PUBLIC_SISALCARPET_ROOT_URL,
  synsisalRootUrl: process.env.NEXT_PUBLIC_SYNSISAL_ROOT_URL,
  furnitureProductPricesApiKey: process.env.FURNITURE_PRODUCT_PRICES_API_KEY,
  backendURL: process.env.NEXT_PUBLIC_BACKEND_URL,
  stripe: {
    publishableKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,
  },
  allowCrawling: process.env.NEXT_PUBLIC_ALLOW_CRAWLING,
  originUrl: process.env.ORIGIN_URL,
  hubspot: {
    apiKey: process.env.NEXT_HUBSPOT_API_KEY,
  },
  cloudinary: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    apiKey: process.env.CLOUDINARY_API_KEY,
    apiSecret: process.env.CLOUDINARY_API_SECRET,
    appOrigin: process.env.CLOUDINARY_APP_ORIGIN,
  },
  cloudinaryCloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  smartyStreetsKey: process.env.NEXT_PUBLIC_SMARTY_KEY || '',
}
